import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Constant from "../Constant";

import "../../css/style.css";
import $ from "jquery";
import { getAccessToken } from "../../hooks/AccessToken";

class DataManagement extends Component {
  state = {
    ERROR: true,
    DataList: [],
    // default filter is "all" to show all contests initially
    selectValue: "all",
    length: "",
    pageNo: "",
    defaultPageNo: 1,
    searchKey: "",
    sortByAttribute: 1,
    showStatusDialog: false,
    selectedContestId: null,
    selectedContestStatus: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  // Show confirmation dialog
  showStatusConfirmation = (id, status) => {
    this.setState({
      showStatusDialog: true,
      selectedContestId: id,
      selectedContestStatus: status,
    });
  };

  // Hide confirmation dialog
  hideStatusConfirmation = () => {
    this.setState({
      showStatusDialog: false,
      selectedContestId: null,
      selectedContestStatus: null,
    });
  };

  //----------------------------------Change status-----------------------------------------------\\
  changeStatus = async (id, status) => {
    try {
      let token = getAccessToken();
      const response = await Axios.patch(
        Constant.apiBasePath + "contest/changeStatus/" + id,
        { status: status },
        { headers: { token: token } }
      );
      let { data } = response;
      if (data.status === Constant.statusSuccess) {
        $("#gen-message").text(data.message);
        $("#gen-message").css("color", "green");
        this.handleSubmit(this.state.defaultPageNo);
      } else {
        $("#gen-message").text(data.message);
        $("#gen-message").css("color", "red");
      }
    } catch (err) {
      console.log(err);
    }
    this.hideStatusConfirmation();
  };

  //------------------------------------Integrate API for remove contest-------------------------------//
  delete = async (contestId) => {
    try {
      let token = getAccessToken();
      const response = await Axios.delete(
        Constant.apiBasePath + "contest/" + contestId,
        { headers: { token: token } }
      );
      let data = response.data;
      if (data.status === Constant.statusSuccess) {
        $("#gen-message").text(data.message);
        $("#gen-message").css("color", "green");
        this.handleSubmit(this.state.defaultPageNo);
        setTimeout(function () {
          $("#gen-message").text("");
        }, 3000);
      } else {
        $("#gen-message").text(data.message);
        $("#gen-message").css("color", "red");
        setTimeout(function () {
          $("#gen-message").text("");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //----------------------------------Integrate API for update contest-------------------------------\\
  update = async (contestId) => {
    try {
      this.props.history.push("/update-contest/" + contestId);
    } catch (err) {
      console.log(err);
    }
  };

  //----------------------------------Integrate API for show contests----------------------------------\\
  handleSubmit = async (pageNo, sort) => {
    try {
      let token = getAccessToken();
      // Use the state value instead of jQuery to get the filter value.
      let selectValue = this.state.selectValue;
      let searchKey = this.state.searchKey;
      let sortByAttribute = this.state.sortByAttribute;
      if (sort) {
        this.setState({ sortByAttribute: sort });
        sortByAttribute = sort;
      }
      // Sending the current selectValue as "key" to API.
      // (When key is "all", the backend should return all contests.)
      const response = await Axios.post(
        Constant.apiBasePath + "contest/get-all",
        {
          key: selectValue,
          pageNo: pageNo,
          searchKey: searchKey,
          sortByAttribute: sortByAttribute,
        },
        { headers: { token: token } }
      );
      const { data, length, pageNo: currentPage } = response.data;

      // Fetch participant counts for each contest
      const updatedData = await Promise.all(
        data.map(async (contest) => {
          const participantsResponse = await Axios.get(
            `https://api.storytent.in/api/contest/getParticipantsCount/${contest._id}`,
            { headers: { token: token } }
          );
          return {
            ...contest,
            participantCount:
              participantsResponse.data.poemCount > 0
                ? participantsResponse.data.poemCount
                : participantsResponse.data.storyCount || 0,
          };
        })
      );

      this.setState({
        DataList: updatedData,
        length: length,
        pageNo: currentPage,
        defaultPageNo: pageNo,
        // Optional: you may want to clear searchKey here if needed.
        searchKey: "",
        ERROR: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    // Initially fetch all contests.
    this.handleSubmit(this.state.defaultPageNo);
  }

  render() {
    const {
      DataList,
      length,
      pageNo,
      showStatusDialog,
      selectedContestId,
      selectedContestStatus,
      selectValue,
    } = this.state;

    // Apply local filtering based on the selectValue.
    const filteredDataList = DataList.filter((contest) => {
      if (selectValue === "all") return true;
      if (selectValue === "1") return contest.status === 1;
      if (selectValue === "0") return contest.status === 0;
      return true;
    });

    // Calculate serial number based on current page.
    let i = (pageNo - 1) * Constant.perPage;
    let bodyData = "";
    if (filteredDataList.length > 0) {
      bodyData = filteredDataList.map((el) => {
        i++;
        let statusText = el.status === 1 ? "Active" : "Inactive";

        return (
          <tr key={el._id}>
            <td>{i}</td>
            <td>
              <Link to={`contest-management/${el._id}`}>
                <button className="btn btn-primary mt-2">{el.name}</button>
              </Link>
            </td>
            <td>
              <img
                src={el.image}
                alt="contest"
                style={{ width: 50, height: 50 }}
                resizemode="contain"
              />
            </td>
            <td>{el.startDate}</td>
            <td>{el.startTime}</td>
            <td>{el.closureDate}</td>
            <td>{el.closureTime}</td>
            <td>{el.resultDate}</td>
            <td>{el.resultTime}</td>
            <td>{el.runnerUpPrizeMoney}</td>
            <td>{el.winnerPrizeMoney}</td>
            <td>{el.winnerTag}</td>
            <td>{el.participantCount}</td>
            <td>{el.type}</td>
            <td>{el.refId.toString()}</td>
            <td>{el.idCard.toString()}</td>
            <td>{el.cityName.toString()}</td>
            <td>{el.collegeName.toString()}</td>
            <td>{el.collegeAddress?.toString()}</td>
            <td>{el.createdAt}</td>
            <td>
              <button
                className="btn btn-info"
                onClick={() => this.showStatusConfirmation(el._id, el.status)}
              >
                {statusText}
              </button>
            </td>
            <td>
              <button
                className="btn btn-danger Banner-btn"
                onClick={() => {
                  if (window.confirm("Delete the Contest?")) {
                    this.delete(el._id);
                  }
                }}
              >
                <i className="mdi mdi-delete" title="Delete Contest"></i>
              </button>
              <button
                className="btn btn-info Banner-btn edit-banner ml5"
                onClick={() => this.update(el._id)}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  title="Update Contest"
                ></i>
              </button>
            </td>
          </tr>
        );
      });
    }

    let previous = 0;
    let next = 0;
    let customLength = 0;
    if (pageNo !== 0) {
      previous = pageNo - 1;
      next = pageNo + 1;
    }
    if (length !== 0) {
      customLength = length;
    }
    if (length < 2) {
      $(".pagination").hide();
    }

    // Removed jQuery-based change events for #searchKey and #selectValue
    // in favor of controlled components.

    return (
      <div className="content-page">
        <div className="content">
          <div className="row">
            <div className="col-6">
              <div className="page-title-box">
                <h4 className="page-title-heading">Contest Management</h4>
              </div>
            </div>
            <div className="col-6">
              <Link to="/add-contest">
                <button
                  type="button"
                  className="btn btn-info mb-2 mr-2 float-right"
                >
                  Add New
                </button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <select
                        id="selectValue"
                        value={this.state.selectValue}
                        onChange={(e) => {
                          // Update the filter value locally.
                          this.setState({ selectValue: e.target.value });
                        }}
                        className="form-control"
                      >
                        <option value="all">All</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p id="gen-message"></p>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="buttons-table-preview"
                    >
                      <table
                        id="complex-header-datatable"
                        className="table dt-responsive nowrap"
                      >
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>Closure Date</th>
                            <th>Closure Time</th>
                            <th>Result Date</th>
                            <th>Result Time</th>
                            <th>RunnerUp Prize Money</th>
                            <th>Winner Prize Money</th>
                            <th>Winner Tag</th>
                            <th>Participant Count</th>
                            <th>Type</th>
                            <th>refId</th>
                            <th>idCard</th>
                            <th>cityName</th>
                            <th>collegeName</th>
                            <th>collegeAddress</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{bodyData}</tbody>
                      </table>
                      <div className="pagination">
                        {pageNo <= 1 ? (
                          <a>Previous</a>
                        ) : (
                          <a
                            className="paginate-link"
                            onClick={() => this.handleSubmit(previous)}
                          >
                            Previous
                          </a>
                        )}

                        <a>{pageNo}</a>
                        {pageNo >= customLength ? (
                          <a>Next</a>
                        ) : (
                          <a
                            className="paginate-link active"
                            onClick={() => this.handleSubmit(next)}
                          >
                            Next
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showStatusDialog && (
            <div className="custom-modal">
              <div
                className="modal-overlay"
                onClick={this.hideStatusConfirmation}
              ></div>
              <div className="modal-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="modal-header"
                >
                  <h3 style={{ textAlign: "center" }}>Change Contest Status</h3>
                </div>
                <div className="modal-body">
                  <p style={{ textAlign: "center" }}>
                    Are you sure you want to make this contest{" "}
                    {selectedContestStatus === 1 ? "inactive" : "active"}?
                  </p>
                </div>
                <div
                  className="modal-footer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-secondary"
                    onClick={this.hideStatusConfirmation}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.changeStatus(
                        selectedContestId,
                        selectedContestStatus === 1 ? 1 : 0
                      )
                    }
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}

          <style>{`
            .custom-modal {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1050;
            }
            .modal-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 1051;
            }
            .modal-container {
              background: white;
              border-radius: 4px;
              width: 90%;
              max-width: 500px;
              position: relative;
              z-index: 1052;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            }
            .modal-header {
              padding: 15px 20px;
              border-bottom: 1px solid #eee;
            }
            .modal-header h3 {
              margin: 0;
              font-size: 18px;
            }
            .modal-body {
              padding: 20px;
            }
            .modal-footer {
              padding: 15px 20px;
              border-top: 1px solid #eee;
              display: flex;
              justify-content: flex-end;
              gap: 10px;
            }
            .btn-secondary {
              background: #6c757d;
              color: white;
              border: none;
              padding: 8px 16px;
              border-radius: 4px;
              cursor: pointer;
            }
            .btn-secondary:hover {
              background: #5a6268;
            }
            .btn-primary {
              background: #007bff;
              color: white;
              border: none;
              padding: 8px 16px;
              border-radius: 4px;
              cursor: pointer;
            }
            .btn-primary:hover {
              background: #0056b3;
            }
          `}</style>
        </div>
      </div>
    );
  }
}

export default DataManagement;
