import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../../css/style.css";

import $ from "jquery";
import Constant from "../Constant";
import { getAccessToken } from "../../hooks/AccessToken";

class AddUpdate extends Component {
  state = {
    name: "",
    image: null,
    about: "",
    startDate: "",
    startTime: "",
    closureDate: "",
    closureTime: "",
    resultDate: "",
    resultTime: "",
    totalNumberOfWinners: "",
    storyPrice: "",
    poemPrice: "",
    runnerUpPrizeMoney: "",
    type: "story",
    winnerPrizeMoney: "",
    winnerTag: "",
    idCard: false,
    refId: false,
    cityName: false, // Added field
    collegeName: false, // Added field
    collegeAddress: false, // Added field
    getData: {},
    selectArea: 0,
    ERROR: true,
  };

  handleChange = (e) => {
    const { id, value } = e.target;

    // Added cityName and collegeName to boolean conversion
    if (
      id === "idCard" ||
      id === "refId" ||
      id === "cityName" ||
      id === "collegeName" ||
      id === "collegeAddress"
    ) {
      this.setState({
        [id]: value === "true",
      });
    } else {
      this.setState({
        [id]: value,
      });
    }
  };

  handleImageChange = (e) => {
    this.setState({
      [e.target.id]: e.target.files[0],
    });
  };

  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let token = getAccessToken();
      let id = this.props.match.params.id;
      const form_data = new FormData();

      if (id === undefined) {
        console.log("clicked add button");

        if (this.state.image === null) {
          $("#error-banner-msg").text("File is required");
          $("#error-banner-msg").css("color", "red");
          return false;
        }

        let fileName = this.state.image.name;
        let extension = fileName.split(".").pop();

        if (extension !== "jpg" && extension !== "png") {
          $("#error-banner-msg").text(
            "Only jpg and png file type are allowed!"
          );
          $("#error-banner-msg").css("color", "red");
          return false;
        }

        // Updated to include cityName and collegeName
        Object.entries(this.state).forEach(([key, value]) => {
          if (
            key === "idCard" ||
            key === "refId" ||
            key === "cityName" ||
            key === "collegeName" ||
            key === "collegeAddress"
          ) {
            console.log("doing boolean values");

            form_data.append(key, Boolean(value));
          } else if (
            key !== "getData" &&
            key !== "ERROR" &&
            key !== "selectArea" &&
            value !== null
          ) {
            if (key === "image") {
              form_data.append(key, value, value.name);
            } else {
              form_data.append(key, value);
            }
          }
        });
      } else {
        let getData = this.state.getData;

        const appendFormData = (key, stateValue, defaultValue) => {
          if (
            key === "idCard" ||
            key === "refId" ||
            key === "cityName" ||
            key === "collegeName" ||
            key === "collegeAddress"
          ) {
            const value =
              stateValue !== undefined
                ? Boolean(stateValue)
                : Boolean(defaultValue);
            form_data.append(key, value);
          } else if (stateValue) {
            if (key === "image" && stateValue.name) {
              form_data.append(key, stateValue, stateValue.name);
            } else {
              form_data.append(key, stateValue);
            }
          } else if (defaultValue !== undefined) {
            form_data.append(key, defaultValue);
          }
        };

        // All form fields including new ones
        appendFormData("name", this.state.name, getData.name);
        appendFormData("image", this.state.image, getData.image);
        appendFormData("about", this.state.about, getData.about);
        appendFormData("startDate", this.state.startDate, getData.startDate);
        appendFormData("startTime", this.state.startTime, getData.startTime);
        appendFormData(
          "closureDate",
          this.state.closureDate,
          getData.closureDate
        );
        appendFormData(
          "closureTime",
          this.state.closureTime,
          getData.closureTime
        );
        appendFormData("resultDate", this.state.resultDate, getData.resultDate);
        appendFormData("resultTime", this.state.resultTime, getData.resultTime);
        appendFormData("type", this.state.type, getData.type);
        appendFormData(
          "totalNumberOfWinners",
          this.state.totalNumberOfWinners,
          getData.totalNumberOfWinners
        );
        appendFormData("storyPrice", this.state.storyPrice, getData.storyPrice);
        appendFormData("poemPrice", this.state.poemPrice, getData.poemPrice);
        appendFormData(
          "runnerUpPrizeMoney",
          this.state.runnerUpPrizeMoney,
          getData.runnerUpPrizeMoney
        );
        appendFormData(
          "winnerPrizeMoney",
          this.state.winnerPrizeMoney,
          getData.winnerPrizeMoney
        );
        appendFormData("winnerTag", this.state.winnerTag, getData.winnerTag);
        appendFormData("idCard", this.state.idCard, getData.idCard);
        appendFormData("refId", this.state.refId, getData.refId);
        appendFormData("cityName", this.state.cityName, getData.cityName); // Added
        appendFormData(
          "collegeName",
          this.state.collegeName,
          getData.collegeName
        ); // Added
        appendFormData(
          "collegeAddress",
          this.state.collegeAddress,
          getData.collegeAddress
        ); // Added
      }
      console.log("after everything");

      const url =
        id === undefined
          ? Constant.apiBasePath + "contest/create"
          : Constant.apiBasePath + "contest/edit/" + id;

      const method = id === undefined ? Axios.post : Axios.patch;
      console.log(method);

      method(url, form_data, {
        headers: { token: token },
      }).then((response) => {
        const getResults = response.data.message;

        if (response.data.status === Constant.statusSuccess) {
          $("#gen-message").text(getResults);
          $("#gen-message").css("color", "green");
          setTimeout(function () {
            window.location.href = "/contest-management";
          }, 3000);
        } else {
          $("#gen-message").text(getResults);
          $("#gen-message").css("color", "red");
          return false;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  getgetData(id) {
    let token = getAccessToken();
    let req = {
      method: "GET",
      url: Constant.apiBasePath + "contest/getDataById/" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: token,
      },
      json: true,
    };
    Axios(req)
      .then((response) => {
        const { data } = response;
        if (data.status === Constant.statusSuccess) {
          const contestData = data.data[0];
          this.setState({
            getData: contestData,
            idCard: Boolean(contestData.idCard),
            refId: Boolean(contestData.refId),
            cityName: Boolean(contestData.cityName), // Added
            collegeName: Boolean(contestData.collegeName), // Added
            collegeAddress: Boolean(contestData.collegeAddress), // Added
            ERROR: false,
          });
        } else {
          this.setState({ getData: "", ERROR: false });
        }
      })
      .catch((error) => "Something Error");
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id !== undefined) {
      this.getgetData(id);
    }
  }

  render() {
    const { getData } = this.state;
    let name = "";
    let image = "";
    let about = "";
    let startDate = "";
    let startTime = "";
    let closureDate = "";
    let closureTime = "";
    let resultDate = "";
    let resultTime = "";
    let totalNumberOfWinners = "";
    let storyPrice = "";
    let poemPrice = "";
    let runnerUpPrizeMoney = "";
    let winnerPrizeMoney = "";
    let type = "story";
    let winnerTag = "";
    let idCard = false;
    let refId = false;
    let cityName = false; // Added
    let collegeName = false; // Added
    let collegeAddress = false; // Added

    let btnVal = "Add";
    let heading = "Add Contest";

    if (getData.name !== undefined) {
      name = getData.name;
      image = getData.image === undefined ? "" : getData.image;
      about = getData.about;
      startDate = getData.startDate;
      startTime = getData.startTime;
      closureDate = getData.closureDate;
      closureTime = getData.closureTime;
      resultDate = getData.resultDate;
      resultTime = getData.resultTime;
      totalNumberOfWinners = getData.totalNumberOfWinners;
      storyPrice = getData.storyPrice;
      poemPrice = getData.poemPrice;
      runnerUpPrizeMoney = getData.runnerUpPrizeMoney;
      winnerPrizeMoney = getData.winnerPrizeMoney;
      winnerTag = getData.winnerTag;
      type = getData.type;
      idCard = getData.idCard === undefined ? false : getData.idCard;
      refId = getData.refId === undefined ? false : getData.refId;
      cityName = getData.cityName === undefined ? false : getData.cityName; // Added
      collegeName =
        getData.collegeName === undefined ? false : getData.collegeName; // Added
      collegeAddress =
        getData.collegeAddress === undefined ? false : getData.collegeAddress; // Added
      btnVal = "Update";
      heading = "Update Contest";
    }

    return (
      <div className="content-page">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/contest-management">Contest Management</Link>
                    </li>
                    <li className="breadcrumb-item active">{heading}</li>
                  </ol>
                </div>
                <h4 className="page-title-heading">{heading}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={this.handleSubmit}>
                    <p id="gen-message"></p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="name" className="update">
                            Name *
                          </label>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            defaultValue={name}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="image" className="update">
                            Image *
                          </label>
                          <input
                            type="file"
                            id="image"
                            className="form-control"
                            onChange={this.handleImageChange}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="about" className="update">
                            About *
                          </label>
                          <textarea
                            id="about"
                            className="form-control"
                            defaultValue={about}
                            onChange={this.handleChange}
                            required
                          ></textarea>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="startDate" className="update">
                            Start Date *
                          </label>
                          <input
                            type="date"
                            id="startDate"
                            className="form-control"
                            defaultValue={startDate}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="startTime" className="update">
                            Start Time *
                          </label>
                          <input
                            type="time"
                            id="startTime"
                            className="form-control"
                            defaultValue={startTime}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="closureDate" className="update">
                            Closure Date *
                          </label>
                          <input
                            type="date"
                            id="closureDate"
                            className="form-control"
                            defaultValue={closureDate}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="closureTime" className="update">
                            Closure Time *
                          </label>
                          <input
                            type="time"
                            id="closureTime"
                            className="form-control"
                            defaultValue={closureTime}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="resultDate" className="update">
                            Result Date *
                          </label>
                          <input
                            type="date"
                            id="resultDate"
                            className="form-control"
                            defaultValue={resultDate}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="resultTime" className="update">
                            Result Time *
                          </label>
                          <input
                            type="time"
                            id="resultTime"
                            className="form-control"
                            defaultValue={resultTime}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label
                            htmlFor="totalNumberOfWinners"
                            className="update"
                          >
                            Total Number Of Winners *
                          </label>
                          <input
                            type="number"
                            id="totalNumberOfWinners"
                            className="form-control"
                            defaultValue={totalNumberOfWinners}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="storyPrice" className="update">
                            Story Price *
                          </label>
                          <input
                            type="number"
                            id="storyPrice"
                            className="form-control"
                            defaultValue={storyPrice}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="poemPrice" className="update">
                            Poem Price *
                          </label>
                          <input
                            type="number"
                            id="poemPrice"
                            className="form-control"
                            defaultValue={poemPrice}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label
                            htmlFor="runnerUpPrizeMoney"
                            className="update"
                          >
                            Runner-up Prize Money *
                          </label>
                          <input
                            type="number"
                            id="runnerUpPrizeMoney"
                            className="form-control"
                            defaultValue={runnerUpPrizeMoney}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="winnerPrizeMoney" className="update">
                            Winner Prize Money *
                          </label>
                          <input
                            type="number"
                            id="winnerPrizeMoney"
                            className="form-control"
                            defaultValue={winnerPrizeMoney}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="winnerTag" className="update">
                            Winner Tag *
                          </label>
                          <input
                            type="text"
                            id="winnerTag"
                            className="form-control"
                            defaultValue={winnerTag}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="refId" className="update">
                            Type *
                          </label>
                          <select
                            id="type"
                            className="form-control"
                            value={this.state.type}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="story">Story</option>
                            <option value="poem">poem</option>
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="cityName" className="update">
                            City Name
                          </label>
                          <select
                            id="cityName"
                            className="form-control"
                            value={this.state.cityName}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="collegeName" className="update">
                            College Name
                          </label>
                          <select
                            id="collegeName"
                            className="form-control"
                            value={this.state.collegeName}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="collegeAddress" className="update">
                            College Address
                          </label>
                          <select
                            id="collegeAddress"
                            className="form-control"
                            value={this.state.collegeAddress}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="idCard" className="update">
                            ID Card
                          </label>
                          <select
                            id="idCard"
                            className="form-control"
                            value={this.state.idCard}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="refId" className="update">
                            Ref ID
                          </label>
                          <select
                            id="refId"
                            className="form-control"
                            value={this.state.refId}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group mb-0">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            {btnVal}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUpdate;
