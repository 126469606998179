import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import "../../css/style.css";

const ViewSubmissions = () => {
  const { id: contestId } = useParams();
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [contestDetails, setContestDetails] = useState({});
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        let token = localStorage.getItem("loginDetails");
        const response = await Axios.get(
          `https://api.storytent.in/api/contest/getParticipantsCount/${contestId}`,
          { headers: { token: token } }
        );

        const res = await Axios.get(
          `https://api.storytent.in/api/contest/getDataById/${contestId}`,
          {
            headers: { token: token },
          }
        );
        setContestDetails(res.data.data[0]);

        if (response.data) {
          let allSubmissions = [];
          if (response.data.stories && response.data.stories.length > 0) {
            allSubmissions = [
              ...allSubmissions,
              ...response.data.stories.map((story) => ({
                ...story,
              })),
            ];
          }
          if (response.data.poems && response.data.poems.length > 0) {
            allSubmissions = [
              ...allSubmissions,
              ...response.data.poems.map((poem) => ({ ...poem })),
            ];
          }

          setSubmissions(allSubmissions);
          setFilteredSubmissions(allSubmissions);
        }
      } catch (err) {
        console.error("Error fetching submissions:", err);
      }
    };

    if (contestId) {
      fetchSubmissions();
    }
  }, [contestId]);

  const handleFilterChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    if (language === "") {
      setFilteredSubmissions(submissions);
    } else {
      setFilteredSubmissions(
        submissions.filter((sub) => sub.language === language)
      );
    }
    setCurrentPage(1);
  };

  const deletePoem = async (id) => {
    try {
      let token = localStorage.getItem("loginDetails");
      const response = await Axios.delete(
        `https://api.storytent.in/api/poem/${id}`,
        { headers: { token: token } }
      );
      if (response.data) {
        alert("Poem deleted successfully");
        const updatedPoems = submissions.filter((poem) => poem._id !== id);
        setSubmissions(updatedPoems);
        setFilteredSubmissions(updatedPoems);
      }
    } catch (err) {
      console.error("Error deleting poem:", err);
    }
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages =
    filteredSubmissions.length > 0
      ? Math.ceil(filteredSubmissions.length / itemsPerPage)
      : 1;

  return (
    <div className="content-page">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="page-title-heading">Submissions</h4>
                <div className="mb-3">
                  <label>Filter by Language: </label>
                  <select
                    value={selectedLanguage}
                    onChange={handleFilterChange}
                  >
                    <option value="">All</option>
                    <option value="671b2a34cac45040c412852a">English</option>
                    <option value="671b2a34cac45040c412852d">Hindi</option>
                  </select>
                </div>
                <table className="table dt-responsive nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Thumbnail</th>
                      <th>Likes</th>
                      <th>Language</th>
                      {contestDetails.idCard && <th>College ID</th>}
                      {contestDetails.collegeName && <th>College Name</th>}
                      {contestDetails.collegeAddress && (
                        <th>College Address</th>
                      )}
                      {contestDetails.refId && <th>Ref ID</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((submission, index) => (
                        <tr key={index}>
                          <td>{submission.name}</td>
                          <td>{submission.description}</td>
                          <td>
                            <img
                              src={submission.thumbnail}
                              alt="Thumbnail"
                              style={{ width: 50, height: 50 }}
                            />
                          </td>
                          <td>
                            {submission.likes.length > 0
                              ? submission.likes.length
                              : 0}
                          </td>
                          <td>
                            {submission.language === "671b2a34cac45040c412852a"
                              ? "English"
                              : submission.language ===
                                "671b2a34cac45040c412852d"
                              ? "Hindi"
                              : submission.language}
                          </td>
                          {contestDetails.idCard && (
                            <td>
                              <img
                                src={submission.idCard}
                                alt="Thumbnail"
                                style={{
                                  minWidth: 50,
                                  maxWidth: 80,
                                  minHeight: 50,
                                  maxHeight: 80,
                                }}
                              />
                            </td>
                          )}
                          {contestDetails.collegeName && (
                            <td>{submission.collegeName}</td>
                          )}
                          {contestDetails.collegeAddress && (
                            <th>{submission.collegeAddress}</th>
                          )}
                          {contestDetails.refId && <td>{submission.refId}</td>}

                          <td>
                            <button
                              className="btn btn-danger Banner-btn updatebtns"
                              onClick={() => {
                                if (window.confirm("Delete the poem?")) {
                                  deletePoem(submission._id);
                                }
                              }}
                            >
                              <i
                                className="mdi mdi-delete"
                                title="Delete Poem"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No submissions available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    {" "}
                    Page {currentPage} of {totalPages}{" "}
                  </span>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSubmissions;
